import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":_vm.getTitle},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c(VBtn,{attrs:{"disabled":!_vm.valid,"large":"","depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])]},proxy:true}])},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"overflow-y-auto px-0 pt-3",style:(_vm.getViewHeightStyle(36))},[_c(VForm,{ref:"form",staticClass:"py-5 px-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"counter":64,"rules":_vm.rules.name,"label":_vm.$t('general.name'),"required":"","dense":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"counter":64,"rules":_vm.rules.name,"label":_vm.$t('general.nameAr'),"required":"","dense":""},model:{value:(_vm.model.nameAr),callback:function ($$v) {_vm.$set(_vm.model, "nameAr", $$v)},expression:"model.nameAr"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"counter":255,"rules":_vm.rules.limited255,"label":_vm.$t('general.description'),"dense":"","rows":"3"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)],1),_c(VRow,[_c(VCol),_c(VCol,[_c(VCheckbox,{attrs:{"label":_vm.$t('general.active'),"dense":""},model:{value:(_vm.model.active),callback:function ($$v) {_vm.$set(_vm.model, "active", $$v)},expression:"model.active"}})],1)],1)],1)],1),_c('br'),_c('form-bar',{attrs:{"valid":_vm.valid},on:{"save":_vm.save,"reset":_vm.reset,"validate":function($event){return _vm.$refs.form.validate()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }