<template>
  <v-container class="white px-0" fluid>
    <v-card flat>
      <category-form :model="item" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CategoryForm from "./CategoryForm";

export default {
  name: "CategoryEditor",
  components: { CategoryForm },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("categories", ["getCategoryById"]),

    idParam() {
      return parseInt(this.$route.params.categoryId);
    },
    parentIdParam() {
      return parseInt(this.$route.params.sectionId);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  mounted() {
    if (this.editMode) {
      this.item = this.getCategoryById(this.idParam);
      if (!this.item) {
        this.loadCategoryById(this.idParam).then(() => {
          this.item = this.getCategoryById(this.idParam);
        });
      }
    } else {
      setTimeout(() => {
        this.item = {
          sectionId: this.parentIdParam,
        };
      }, 300);
    }
  },
  methods: {
    ...mapActions("categories", ["loadCategoryById", "saveCategory"]),

    save(params) {
      const { item, editMode } = params;
      this.saveCategory({ item, editMode }).then(() => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
